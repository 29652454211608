<template>
    <div class="input-group input-group-sm">
      <label v-if="column.tipo !== 'checkbox'" :for="column.nome" class="col-sm-2 input-group-addon bg-light">{{ column.titulo }}<small class="mct-obg" v-if="column.required"> * </small></label>
      
      <input v-if="column.tipo in {'text':1, 'number':1, 'date':1}" :type="column.tipo" :id="column.nome" :placeholder="column.titulo" class="form-control"  
        v-model="lista[column.nome]" 
        @input="forceUppercase($event, lista, `${column.nome}`, `${column.case}`)"  
        :class="[column.required ? 'border-red' : 'border-seq', `col-md-${column.tamanho}`, {'is-invalid': errors[column.nome]}]" 
        :readonly="modo === 'deletar' || modo === 'visualizar' || column.autoincremento"
        :required="column.required"
        >

      <select v-if="column.tipo === 'select' && !column.data" class="form-control" :id="column.nome" :placeholder="column.titulo"
        :class="[column.required ? 'border-red' : 'border-seq', `col-md-${column.tamanho}`, {'is-invalid': errors[column.nome]}]"        
        v-model="lista[column.nome]"
        :disabled="modo === 'deletar' || modo === 'visualizar' || column.autoincremento"
        :required="column.required"  
        >
        <option v-for="(registro,index) in opcoes" :key="index" :value="registro[column.origem_chave]">{{ registro[column.origem_campo] }}</option>
      </select>

      <select v-if="column.tipo === 'select' && column.data" class="form-control" :id="column.nome" :placeholder="column.titulo"
        :class="[column.required ? 'border-red' : 'border-seq', `col-md-${column.tamanho}`, {'is-invalid': errors[column.nome]}]"        
        v-model="lista[column.nome]"
        :disabled="modo === 'deletar' || modo === 'visualizar' || column.autoincremento"
        :required="column.required"  
        >
        <option v-for="(registro,index) in column.data" :key="index" :value="registro.value">{{ registro.text }}</option>
      </select>

      <div class="form-control" v-if="column.tipo === 'radio' && column.data"
        :class="[column.required ? 'border-red' : 'border-seq', `col-md-${column.tamanho}`, {'is-invalid': errors[column.nome]}]"
        :required="column.required">
        <label class="custom-control custom-radio align-middle" v-for="(registro,index) in column.data" :key="index">
          <input id="column.nome" name="column.nome" type="radio" class="custom-control-input" :value="registro.value" v-model="lista[column.nome]" :disabled="modo === 'deletar' || modo === 'visualizar' || column.autoincremento">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description align-middle">{{ registro.text }}</span>
        </label>        
      </div>

      <label v-if="column.tipo === 'checkbox'" class="custom-control custom-checkbox">
        <input  class="custom-control-input" type="checkbox" name="column.nome" v-model="lista[column.nome]"
          v-bind:true-value="1"
          v-bind:false-value="0">
        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">{{column.titulo}}</span>
      </label>

      <input type="text" class="form-control col-md-1" :id="column.nome" :placeholder="column.titulo"
        :class="[column.required ? 'border-red' : 'border-seq', {'is-invalid': errors[column.nome]}]"        
        v-model="lista[column.nome]"
        :disabled="modo === 'deletar' || modo === 'visualizar' || column.autoincremento"
        :required="column.required" 
        v-if="column.tipo === 'search'"
        @keyup.enter="getDescricao(lista[column.nome],column.origem,column.origem_campo)"
        @blur="getDescricao(lista[column.nome],column.origem,column.origem_campo)"
        >
      <span class="input-group-btn" 
        v-if="column.tipo === 'search'">
        <button class="btn btn-secondary btn-sm" type="button" id="btnPesquisa" @click="evtModal($event)"
          :disabled="modo === 'deletar' || modo === 'visualizar' || column.autoincremento"><i class="fa fa-search" aria-hidden="true"></i></button>
      </span>
      <span class="input-group-addon" :class="[`col-md-${column.tamanho}`]"
        v-if="column.tipo === 'search'" :id="`dsc${column.nome}`">{{ retornoDescricao }}</span>        

      <div class="invalid-feedback" style="padding-left: 5px;" v-if="errors[column.nome]">
        * {{ errors[column.nome][0] }}
      </div>  
    </div>
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
//import MctModal from './MctModal.vue'
//import MctDataViewer from './MctDataViewer.vue'

export default {
    props: ['column','lista', 'modo', 'mensagem', 'errors'],
    components :{
    //  'MctModal': MctModal,
    //  'MctDataViewer': MctDataViewer
    },
    data () {
      return {
        opcoes: {},
        retornoDescricao: '',
        showPesquisa: false
        //upperCaseB: false 
      }
    },
    methods: {
      forceUppercase(e, o, prop, c) {
          const start = e.target.selectionStart
          if ((c === 'undefined') || (c === '') || (c === 'upper')){ 
            e.target.value = e.target.value.toUpperCase()
            }else
          if (c === 'lower'){ 
            e.target.value = e.target.value.toLowerCase()
            }            
          this.$set(o, prop, e.target.value)
          e.target.setSelectionRange(start, start)
      },

      getLista () {
        var vm = this
        //console.log(tabela)
        axios.get(`${this.column.origem}/combo`)
          .then(function(response) {
            Vue.set(vm.$data, 'opcoes', response.data)
          })
          .catch(function(response) {
            console.log(response)
          })
      },

      getDescricao (busca, origem, retorno) {
        //ev.preventDefault()
        var vm = this
        //console.log(tabela)
        axios.get(`${origem}/editar/${busca}`)
          .then(function(response) {
            Vue.set(vm.$data, 'retornoDescricao', response.data.data[retorno])
          })
          .catch(function(response) {
            if (response.response.status === 404) {
              Vue.set(vm.$data, 'retornoDescricao', 'REGISTRO NÃO LOCALIZADO')
            }
          })
      }, 
          
      evtModal (ev) {
        this.$emit('evtModal', this.column)        
      }

    },
    mounted: function() {
      //console.log(this.column.data.length)
      if ((this.column.tipo === 'select') && (!this.column.data)){
        this.getLista()  
      }else 
      if (this.column.tipo === 'search'){
        this.getDescricao (this.lista[this.column.nome],this.column.origem,this.column.origem_campo)  
      }
    },
    created() {

    },  

  watch: {
    /*lista[column.nome]: function(val, oldVal) {
      // change of userinput, do something
    }*/
  }    
}
</script>

<style scoped>

  label{
    margin-bottom: 0;
  }

</style>